<script setup>

import { ref, onMounted } from 'vue'
import AnnouncementComponent from '../components/advertisements/AnnouncementComponent.vue'
import BsModal from '../../frontend/components/BsModal.vue'
import Nav from '../../frontend/components/layout/Nav.vue'
import headComponent from "../components/inertiaHead/head.vue"


// const activeTab = ref(1)

const props = defineProps({
    is_authenticated:
    {
        type: Boolean,
        default: false
    },
    user: {
        type: Object
    },
    all_internal_comapnies_with_slug: {
        type: Array,
        default: [],
    },

});

const showPopup = ref(false)
const activeTab = ref(1)
const urls = ref({})

onMounted(() => {
    let windowWidth = ref(window.innerWidth)
    if (windowWidth.value < 420) {
        // showPopup.value = true
    }
})

</script>

<script>

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { EffectCoverflow, Autoplay } from 'swiper/core';

const isMobile = ref(false)

export default {
    mounted() {
        isMobile.value = this.checkIfMobile();
        window.addEventListener('resize', this.updateIsMobile);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateIsMobile);
    },

    methods: {
        checkIfMobile() {
            return window.innerWidth <= 768;
        },
        updateIsMobile() {
            isMobile.value = this.checkIfMobile();
        }
    }
}

</script>

<template>
    <headComponent
        :description="'Experience the power of SearchSpace AI. Leveraging Artificial Intelligence to provide the latest and best data on gold mining, junior mining stocks, and more. Join us as we redefine mining.'"
        :title="'Mining investment analysis AI'">
    </headComponent>
    <!-- AI banner starts -->
    <div class="container-fluid AI-banner-img d-flex justify-content-center align-items-center flex-column px-3">
        <h1 class="mb-3">SearchSpace AI Analysis | Revolutionizing Mining and Mining Investment Analysis AI</h1>
        <h3 class="mb-3 mb-sm-4">Taking you closer to your Investment goals</h3>
        <div class="">
            <a>
                <p class="mb-0">We use Artificial Intelligence (AI) to analyse new-releases and other published data
                    of
                    Canadian mining companies to extract information that may be useful in research and
                    decision-making.
                    Our analysis may be helpful to mining companies, asset managers, investors and others to make
                    portfolio management, capital allocation, business risk evaluation decisions and many more. We
                    present some of the data analysis you may not have seen before.</p>
            </a>
        </div>
    </div>
    <!-- AI banner ends -->
    <div class="container tw-left-0 tw-right-0 tw-z-[1] tw-pointer-events-none sm:tw-flex" style="max-width: 1500px;">
        <div id="announcement-dt"
            class="container-fluid tw-pointer-events-auto tw-w-[1358px] tw-mr-4 tw-mt-2 tw-mb-1 tw-hidden sm:tw-block" style="margin:0">
            <AnnouncementComponent type="desktop" :view="'AIpage'" :user="user" />
        </div>
    </div>
    <div id="announcement-mobile" class="sm:tw-hidden  tw-left-0 tw-w-full tw-z-10 tw-p-3" style="height:16%;">
        <AnnouncementComponent type="mobile" :view="'AIpage'" :user="user" />
    </div>

    <!-- AI Tab section starts -->
    <div class="container-fluid background-color pb-4">

        <!-- Start: Mobile Menu -->
        <div class="home-tabs-mobile" v-if="isMobile">
            <swiper class="nav nav-pills ai-mobile-tabs" 
                    :slidesPerView="'auto'"
                    :autoHeight="true"
                    :centeredSlides="true"
                    :initialSlide="0">

                <swiper-slide class="nav-item">
                    <a class="nav-link" :class="{ 'active': activeTab == 1 }" @click.prevent="activeTab = 1">
                        <img class="change-img" src="../assets/img/data-analytic.png" alt="">
                        <img class="change-img-active" src="../assets/img/data-analytic-white.png" alt="">
                        Company Analysis Explorer
                    </a>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 2 }" @click.prevent="activeTab = 2">
                            <img class="change-img" src="../assets/img/peoples-web.png" alt="">
                            <img class="change-img-active" src="../assets/img/peoples-web-white.png" alt="">
                            People Web
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/peoples-web.png" alt="">
                            <img class="change-img-active" src="../assets/img/peoples-web-white.png" alt="">
                            People Web
                        </a>  
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 3 }" @click.prevent="activeTab = 3">
                            <img class="change-img" src="../assets/img/people-profiles.png" alt="">
                            <img class="change-img-active" src="../assets/img/people-profiles-white.png" alt="">
                            People Profiles
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/people-profiles.png" alt="">
                            <img class="change-img-active" src="../assets/img/people-profiles-white.png" alt="">
                            People Profiles
                        </a> 
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 4 }" @click.prevent="activeTab = 4">
                            <img class="change-img" src="../assets/img/investment-news-explorer.png" alt="">
                            <img class="change-img-active" src="../assets/img/investment-news-explorer-white.png" alt="">
                            Investment News Explorer
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/investment-news-explorer.png" alt="">
                            <img class="change-img-active" src="../assets/img/investment-news-explorer-white.png" alt="">
                            Investment News Explorer
                        </a> 
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 5 }" @click.prevent="activeTab = 5">
                            <img class="change-img" src="../assets/img/investment-network.png" alt="">
                            <img class="change-img-active" src="../assets/img/investment-network-white.png" alt="">
                            Investment Network
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/investment-network.png" alt="">
                            <img class="change-img-active" src="../assets/img/investment-network-white.png" alt="">
                            Investment Network
                        </a>  
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 6 }" @click.prevent="activeTab = 6">
                            <img class="change-img" src="../assets/img/sentiment-analysis.png" alt="">
                            <img class="change-img-active" src="../assets/img/sentiment-analysis-white.png" alt="">
                            Sentiment Analysis
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/sentiment-analysis.png" alt="">
                            <img class="change-img-active" src="../assets/img/sentiment-analysis-white.png" alt="">
                            Sentiment Analysis
                        </a>  
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 7 }" @click.prevent="activeTab = 7">
                            <img class="change-img" src="../assets/img/press-release-data-vs-stock-price.png" alt="">
                            <img class="change-img-active" src="../assets/img/press-release-data-vs-stock-price-white.png" alt="">
                            Press Release Data vs Stock Price
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/press-release-data-vs-stock-price.png" alt="">
                            <img class="change-img-active" src="../assets/img/press-release-data-vs-stock-price-white.png" alt="">
                            Press Release Data vs Stock Price
                        </a>  
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 8 }" @click.prevent="activeTab = 8">
                            <img class="change-img" src="../assets/img/stock-price-comparison.png" alt="">
                            <img class="change-img-active" src="../assets/img/stock-price-comparison-white.png" alt="">
                            Stock Price Comparison
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/stock-price-comparison.png" alt="">
                            <img class="change-img-active" src="../assets/img/stock-price-comparison-white.png" alt="">
                            Stock Price Comparison
                        </a>  
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 9 }" @click.prevent="activeTab = 9">
                            <img class="change-img" src="../assets/img/insights.png" alt="">
                            <img class="change-img-active" src="../assets/img/insights-white.png" alt="">
                            Investor Asset Explorer
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/insights.png" alt="">
                            <img class="change-img-active" src="../assets/img/insights-white.png" alt="">
                            Investor Asset Explorer
                        </a>  
                    </template>
                </swiper-slide>

                <swiper-slide class="nav-item">
                    <template v-if="user != null">
                        <a class="nav-link" :class="{ 'active': activeTab == 10 }" @click.prevent="activeTab = 10">
                            <img class="change-img" src="../assets/img/metrics.png" alt="">
                            <img class="change-img-active" src="../assets/img/metrics-white.png" alt="">
                            Investor Asset Insights
                        </a>
                    </template>
                    <template v-else>
                        <a class="nav-link" href="/signin">
                            <img class="change-img" src="../assets/img/metrics.png" alt="">
                            <img class="change-img-active" src="../assets/img/metrics-white.png" alt="">
                            Investor Asset Insights
                        </a>  
                    </template>
                </swiper-slide>

            </swiper>
        </div>
        <!-- // End: Mobile Menu -->

        <div class="home-filer-tab" :class="{'container d-flex px-md-0': !isMobile}">
            <div class="me-3 pt-4" v-if="!isMobile">
                <ul class="nav nav-pills flex-column ai-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ 'active': activeTab == 1 }" @click.prevent="activeTab = 1"><img
                                class="change-img" src="../assets/img/data-analytic.png" alt="">
                            <img class="change-img-active" src="../assets/img/data-analytic-white.png" alt="">
                        </a><span class="tooltip-text" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom"
                            title="Tooltip on bottom">Company Analysis Explorer</span>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 2 }" @click.prevent="activeTab = 2"><img
                                    class="change-img" src="../assets/img/peoples-web.png" alt="">
                                <img class="change-img-active" src="../assets/img/peoples-web-white.png" alt="">
                            </a><span class="tooltip-text">People Web</span>

                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/peoples-web.png" alt="">
                                <img class="change-img-active" src="../assets/img/peoples-web.png" alt="">
                            </a><span class="tooltip-text">People Web</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 3 }" @click.prevent="activeTab = 3"><img
                                    class="change-img" src="../assets/img/people-profiles.png" alt="">
                                <img class="change-img-active" src="../assets/img/people-profiles-white.png" alt="">
                            </a><span class="tooltip-text">People Profiles</span>

                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/people-profiles.png" alt="">
                                <img class="change-img-active" src="../assets/img/people-profiles.png" alt="">
                            </a><span class="tooltip-text">People Profiles</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 4 }" @click.prevent="activeTab = 4"><img
                                    class="change-img" src="../assets/img/investment-news-explorer.png" alt="">
                                <img class="change-img-active" src="../assets/img/investment-news-explorer-white.png"
                                    alt=""></a><span class="tooltip-text">Investment News Explorer</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/investment-news-explorer.png" alt="">
                                <img class="change-img-active" src="../assets/img/investment-news-explorer-white.png"
                                    alt=""></a><span class="tooltip-text">Investment News Explorer</span>
                        </template>

                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 5 }" @click.prevent="activeTab = 5"><img
                                    class="change-img" src="../assets/img/investment-network.png" alt="">
                                <img class="change-img-active" src="../assets/img/investment-network-white.png" alt=""></a>
                            <span class="tooltip-text">Investment Network</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/investment-network.png" alt="">
                                <img class="change-img-active" src="../assets/img/investment-network-white.png" alt=""></a>
                            <span class="tooltip-text">Investment Network</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 6 }" @click.prevent="activeTab = 6"><img
                                    class="change-img" src="../assets/img/sentiment-analysis.png" alt="">
                                <img class="change-img-active" src="../assets/img/sentiment-analysis-white.png" alt=""></a>
                            <span class="tooltip-text">Sentiment Analysis</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/sentiment-analysis.png" alt="">
                                <img class="change-img-active" src="../assets/img/sentiment-analysis-white.png" alt=""></a>
                            <span class="tooltip-text">Sentiment Analysis</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 7 }" @click.prevent="activeTab = 7"><img
                                    class="change-img" src="../assets/img/press-release-data-vs-stock-price.png" alt="">
                                <img class="change-img-active"
                                    src="../assets/img/press-release-data-vs-stock-price-white.png" alt=""></a><span
                                class="tooltip-text">Press Release Data vs Stock Price</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/press-release-data-vs-stock-price.png" alt="">
                                <img class="change-img-active"
                                    src="../assets/img/press-release-data-vs-stock-price-white.png" alt=""></a><span
                                class="tooltip-text">Press Release Data vs Stock Price</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 8 }" @click.prevent="activeTab = 8"><img
                                    class="change-img" src="../assets/img/stock-price-comparison.png" alt="">
                                <img class="change-img-active" src="../assets/img/stock-price-comparison-white.png"
                                    alt=""></a>
                            <span class="tooltip-text">Stock Price Comparison</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/stock-price-comparison.png" alt="">
                                <img class="change-img-active" src="../assets/img/stock-price-comparison-white.png"
                                    alt=""></a>
                            <span class="tooltip-text">Stock Price Comparison</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 9 }" @click.prevent="activeTab = 9"><img
                                    class="change-img" src="../assets/img//insights.png" alt="">
                                <img class="change-img-active" src="../assets/img/insights-white.png" alt=""></a>
                            <span class="tooltip-text">Investor Asset Explorer</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                src="../assets/img//insights.png" alt="">
                                <img class="change-img-active"  src="../assets/img/insights-white.png" alt=""></a>
                            <span class="tooltip-text">Investor Asset Explorer</span>
                        </template>
                    </li>
                    <li class="nav-item">
                        <template v-if="user != null">
                            <a class="nav-link" :class="{ 'active': activeTab == 10 }" style="white-space: nowrap;"
                                @click.prevent="activeTab = 10"><img class="change-img"
                                    src="../assets/img/metrics.png" alt="">
                                <img class="change-img-active" src="../assets/img/metrics-white.png"  alt=""></a>
                            <span class="tooltip-text">Investor Asset Insights</span>
                        </template>
                        <template v-else>
                            <a class="nav-link" :href="'/signin'"><img class="change-img"
                                    src="../assets/img/metrics.png" alt="">
                                <img class="change-img-active" src="../assets/img/metrics-white.png" alt=""></a>
                            <span class="tooltip-text">Investor Asset Insights</span>
                        </template>
                    </li>
                </ul>
            </div>

            <!-- Tab panes -->
            <div class="tab-content d-flex ai-sub-section pt-4 pb-4" :class="{'px-3': !isMobile}">
                <div v-if="activeTab === 1" id="company-analysis" class="pb-1 active" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Company Analysis Explorer
                        <sup class="">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type the name to search and click eraser to delete the name</li>
                            <li>Click on the name to select and click again to deselect</li>
                            <li>Use the slider to adjust the date range</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">This analysis helps to learn more about a company's evolution over time. Included
                        are sentiment, emotion, connected individuals, investment details, and stock details for the company
                        over time.</p>
                    <iframe title="Company Analysis Explorer"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiOTdhM2NlNjYtM2ExYy00YjU0LTk3ZDctZjJjNDEzYjZiN2JjIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiMGVkZGY3MjktY2JiNS00Mjg0LWE1MGUtNTMwNjM1NGZkYjg0IiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 2" id="peoples-web" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">People Web <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by
                                name</li>
                            <li>Click on a name to select and click again to deselect</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>CTRL + Click the Reset filters button to remove all filters</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">Here we provide you with the connections between individuals in the Mining
                        Industry
                        in Canada.
                        You could select different filters to expand the network in different ways. You can use the
                        results to research current or historical ties to a company and the people who drive them.</p>
                    <iframe title="People Web"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiNWRhYjhjMTEtNzQ5Zi00YTJjLWI1ZjgtNDE0OTZkMmRmMjQ1IiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiOGE4NWZmZDctZmJkZC00ZTAwLTllMzAtOWI3MGU2OTdlMWJlIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 3" id="people-profiles" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">People Profiles <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>Once a name is selected, the line chart will adjust per person</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">Here, we list the dominant figures in the Canadian Mining Industry, the
                        companies
                        they are affiliated with, and their mentions in various press releases. We also include a
                        summary of the mention of a person or company in press releases. A graph and table depict the
                        investment press releases and general press releases as a function of time. You can choose from
                        a variety of filters to refine your analysis. In the past, in the present, or for a given date
                        range, the advanced analysis will present the industry dominance in terms of individuals and
                        businesses in the field.</p>
                    <iframe title="People Profiles"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiMmUxNzA5ZWEtMWViYi00NzQ5LTg0ZDEtMGM0Mjk2OGIzMTMzIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiZWZlYzVlMzYtZjM2ZC00MzcwLTk2NTktNTdjNzhjNDMzODVkIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 4" id="investment-news-explorer" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Investment News Explorer <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>Click on the bar chart to filter by company name</li>
                            <li>Click bubbles on the map to select location/ click again to deselect
                                Multiple filters:</li>
                            <li>Once a filter is added, hold the CTRL key and click to add another filter</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The analysis reveals companies’ investments. Press releases regarding these
                        investments include company names are indicated. The map depicts global investments with various
                        colours representing various investment types. Upon hovering over the map, investment type and
                        releases are displayed. You can choose from a variety of filters to refine your analysis. The
                        analysis may aid investors and corporations in making investment decisions based on the
                        evolution of global investment.</p>
                    <iframe title="Investment News Explorer"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiMGY1ZDFmNGQtYzI1MS00OTVlLTk0YTQtYWJjMjI0Zjc5YWRiIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiZTljY2NhNmMtYjdjMC00M2FiLTg2YWUtNWM3ZDEwMDVlMmJhIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 5" id="investment-network" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Investment Network <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Type a name to search and click the 'eraser' icon to remove the filter by name</li>
                            <li>Use the slicer to adjust the date range</li>
                            <li>CTRL + Click the Reset filters button to remove all filters</li>
                            <li>The network chart view can be zoomed/clicked and dragged</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The analysis shows companies’ investment networks. Press releases reveal these
                        companies’ investment amounts and types. Filters can refine your analysis. Based on current
                        investments, the analysis may help investors and corporations make investment decisions.</p>
                    <iframe title="Investment Network"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiNzgxNDI2MWQtMDQwNC00MTFlLTkyNGMtZTg3YWVkMDc4MDFjIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiMDA0OTkyOWUtZmRiNS00ZGJlLWI0YjMtMTYxMGQwYmVkZmFjIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 6" id="sentiment-analysis" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Sentiment Analysis <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Use the slicer to adjust the date range</li>
                            <li>Select an article to view sentiments</li>
                            <li>Select an emotion from the radar chart to see emotion keywords</li>
                            <p>Note: Text charts displayed are sentences with the highest negative/positive sentiments</p>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">This analysis will assist you in deciphering the hidden meanings of corporate press
                        releases. Viewers can see the positive and negative sentiments toward the chosen company in the
                        graph. Pick a news item specifically to examine its emotional tone. Indicative of feelings and
                        thoughts are the inclusion of both positive and negative keywords. You can get a more precise
                        result by using one of many available Filters.</p>
                    <iframe title="Sentiment Analysis"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiNmIzOTZlZjQtNjhlNy00ZjIyLWIzMjktMjRiMmEwNjkyMjZhIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiNjkzMjIyZTUtNDFmOS00NThiLTliNGMtNmM2OGE5ZTdjOTIwIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 7" id="press-release-data-vs-stock-price" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Press Release Data vs Stock Price <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Use the slicer to adjust the date range</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">By analysing the impact of a press release on stock prices, investors and
                        businesses
                        can prepare for the future. The analysis considers the stock price chart over the years and the
                        company’s press releases during that time. You can read the press releases and consider their
                        potential effects. Choose the company and time frame of interest using the filters provided.</p>
                    <iframe title="Press Release Data vs Stock Price"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiMTg0N2YxMmQtZDQ3Yy00NzdlLWI0NzctYmZlOWI0ODYxZjM0IiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiNjA2ZmJhOTMtMjk1MC00ZDkyLTlmNjctZjhmMDYxNWNmZjg4IiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 8" id="stock-price-comparison" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Stock Price Comparison <sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Click and select company 1 and 2, and click again to deselect</li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The dashboard provides the ability to evaluate and contrast the effect of a press
                        release on the stock prices of two different companies. Potential investors and businesses may
                        find this analysis useful in laying the groundwork for upcoming investment decisions. You can
                        learn about the outcomes by reading the press releases. It’s also possible to compare stock
                        prices of different companies over time to assess their track records over a longer period of
                        time using this analysis.</p>
                    <iframe title="Stock Price Comparison"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiMTYxYzQwZWYtYjU4Ny00YzgyLTk5YmYtNjQ3NzBjN2E0N2UyIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiMTM4ZTIwN2EtNmZkZC00Y2E3LTliMjAtYzJjZjRlN2UyNjg0IiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 9" id="Investor Asset Explorer" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Investor Asset Explorer <sup class=""><i
                                class="fa fa-info-circle" aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Click on the drop-down menu for <strong>Select Investor</strong>, type the investor’s name to search, and click the eraser to delete the name</li>
                            <li> Click on the name to select and click again to deselect</li>
                            <li>Click on multiple names to select multiple investors at once</li>
                            <li>Similarly, handle <strong>Select Company </strong> and <strong>Select Commodity</strong></li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The analysis reveals the estimated asset value by investors as of today,
                        providing insights into the investment profile, 
                        companies invested in, asset types, transaction details, 
                        and information on buying/selling securities.</p>
                    <iframe title="Investor Asset Explorer"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiOGVhYTM1MTktNDgxMi00N2U2LWI4MGItMDk2NDk3OThjOGViIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D' : 'https://app.powerbi.com/view?r=eyJrIjoiYTYxNjZmZmYtYjJlNS00MjU5LWFlYzItYjUzMDMwZjY4ZjczIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>

                <div v-if="activeTab === 10" id="Investor Asset Insights" class="pb-1" :class="{'container': !isMobile}">
                    <h4 class="ai-info-tooltip">Investor Asset Insights<sup class=""><i class="fa fa-info-circle"
                                aria-hidden="true"></i></sup>
                        <ul class="tooltip-text ps-4">
                            <li>Click on the drop-down menu for <strong>Select Investor</strong>, type the investor’s name to search, and click the eraser to delete the name</li>
                            <li>Click on the name to select and click again to deselect</li>
                            <li>Click on multiple names to select multiple investors at once</li>
                            <li>Similarly, handle <strong>Select Company</strong></li>
                        </ul>
                    </h4>

                    <p class="pt-2 mb-3">The analysis provides insights into the net investment made by investors in each asset. 
                        Additionally, 
                        it reflects the trading stock price during the transaction for each investment.
                    </p>
                    <iframe title="Investor Asset Insights"
                        :src="isMobile ? 'https://app.powerbi.com/view?r=eyJrIjoiOGVhYTM1MTktNDgxMi00N2U2LWI4MGItMDk2NDk3OThjOGViIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D&pageName=f04793cbd7405f3a3ab7' : 'https://app.powerbi.com/view?r=eyJrIjoiMzY4NzI2YmMtOTc5ZC00NTkxLWFjZmQtOThkNjkwNDlkN2JlIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D'"
                        frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>
    </div>
    <!-- AI Tab section ends -->


    <div class="popup-ai-for-mobile">
        <BsModal :show="showPopup">
            <template #modalHeader>
                <img class="info-img-ai" src="../assets/icons/info-svg.svg" alt="">
                <h5 class="modal-title px-2" id="exampleModalLabel ">Info</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showPopup = false"></button>
            </template>
            <template #modalBody>
                <div class="row g-2">
                    <div class="col-md">
                        <h6>Please switch to landscape mode for a better viewing experience.</h6>
                    </div>
                </div>

            </template>
            <!-- <template #modalFooter>
                <button type="button" class="btn btn-primary text-light" data-dismiss="modal"  @click="showPopup = false">Close</button>
            </template> -->
        </BsModal>
    </div>
</template>