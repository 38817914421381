<script setup>
import { ref, computed, onMounted } from 'vue'
import { router, usePage, useForm, Link } from '@inertiajs/vue3'
import VueMultiselectCustom from '../../components/dropdownSelector/VueMultiselectCustom.vue'
import BsModal from '../../components/BsModal.vue'
import PdfModal from "./../../components/modals/PdfModal.vue";
import BsContactModal from '../../components/modals/ConatcUsModal.vue'
import { VueRecaptcha } from 'vue-recaptcha';
import _ from 'lodash'
import { homeFilterState } from './homeFilterState';
import Nav from '../../components/layout/Nav.vue'
import axios from "axios";
import HomeBanner from './HomeBanner.vue'
import useSubscribeNewsletter from "./../../../src/hooks/useSubscribeNewsletter";
import useGetTodaysPressReleases from "../../../src/hooks/press-releases/useGetTodaysPressReleases";
import { useToast } from "primevue/usetoast";
import headComponent from "../../components/inertiaHead/head.vue"

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { EffectCoverflow, Autoplay } from 'swiper/core';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-coverflow';

SwiperCore.use([EffectCoverflow, Autoplay]);

const props = defineProps({
  request_href: {
    type: String,
  },
  csrf_token: {
    type: String,
    required: ""
  },
  baseImageUrl: {
    type: String,
  },
  all_internal_comapnies_with_slug: {
    type: Array,
    default: [],
  },
  all_commodities: {
    type: Array,
  },
  all_countries: {
    type: Array
  },
  all_deals: {
    type: Array,
  },
  all_stages: {
    type: Array
  },
  listings: {
    type: Array,
  },
  listingsCount: {
    type: Number
  },
  heat_map_data: {
    type: Array,
  },
  location: {
    type: Array,
    default: [],
  },
  user: {
    type: Object,
    default: null,
  },
  captchaSiteKey: {
    type: String
  },
  is_deal_type_slug: {
    type: Boolean,
    default: false
  },
  is_country_slug: {
    type: Boolean,
    default: false
  },
  is_stage_slug: {
    type: Boolean,
    default: false
  },
  is_commodity_slug: {
    type: Boolean,
    default: false
  },
  is_home_page_map_view: {
    type: Boolean,
    default: false
  },
  country_name_in_slug:{
    type: String,
    required: ""
  },
  stage_name_in_slug:{
    type: String,
    required: ""
  },
  commodity_name_in_slug:{
    type: String,
    required: ""
  },
  deal_type_name_in_slug:{
    type: String,
    required: ""
  },
  images: {
    type: Array,
  },
  activeMenu: {
    type: String,
    value: 'people-web'
  },
  baseImageUrl: {
      type: String,
  },
})

const currentUrl = computed(() => {
  return new URL(props.request_href);
})

const currentUrlPathName = computed(() => {
  const currentViewIsMap = !!currentUrl.value.searchParams.get('map-view')
  return `${currentUrl.value.pathname}${currentViewIsMap ? '?map-view=true' : ''}`
})

const showFilter = computed(() => homeFilterState.isOpen)

const combinedFilters = computed(() => {

  const selectedPopUpFilter = (() => {
    if (typeof window === 'undefined') return '';
    const urlLocation = window.location;
    const queryString = urlLocation.search;
    const urlParams = new URLSearchParams(queryString);
    const urlString = urlLocation.href;
    const queryObj = [...new URLSearchParams(urlString.split('?')[1])].reduce(
      (a, [k, v]) => ((a[k] = v), a),
      {}
    );

    if (queryObj['map-view'] === 'true') {
      delete queryObj['map-view'];
    }

    const objSize = Object.keys(queryObj).length;

    return objSize === 1
      ? urlParams.get(Object.keys(queryObj)[0])
        ? urlParams.get(Object.keys(queryObj)[0]).split(',').length === 1
          ? urlParams.get(Object.keys(queryObj)[0])
          : ''
        : []
      : '';
  })();
  const selectedCategoryItem = (() => {
    const url = currentUrl.value.pathname;
    const parts = url.split('/');
    const lastParam = parts[parts.length - 1];
    console.log(lastParam);
    return lastParam;
  })();

  return {
    popUpFilter: selectedPopUpFilter,
    categoryItem: selectedCategoryItem,
  };
});

// === filter pop up =========================================================

const filterSelectedCommodities = ref(null)
const filterSelectedCountries = ref(null)
const filterKeyWord = ref('')
const filterComProName = ref('')
const filterSelectedDealType = ref([])
const filterSelectStage = ref([])
const selectedTopSearchCommoditiesLocal = ref('')
const selectedTopSearchCountryLocal = ref('')
const selectedTopSearchKeywordLocal = ref('')

const getQueryFilters = () => {
  const queryString = currentUrl.value.search
  const urlParams = new URLSearchParams(queryString);

  filterKeyWord.value = urlParams.get('keyword') ? urlParams.get('keyword') : ''
  filterComProName.value = urlParams.get('name-keyword') ? urlParams.get('name-keyword') : ''
  filterSelectedCommodities.value = urlParams.get('commodity') ? urlParams.get('commodity').split(",") : []
  filterSelectedCountries.value = urlParams.get('country') ? urlParams.get('country').split(",") : []

  const selectedDealType = urlParams.get('deal-type') ? urlParams.get('deal-type').split(",") : []
  urlParams.get('deal-type') && filterSelectedDealType.value.push(...urlParams.get('deal-type').split(","))

  selectedDealType.forEach(item => {
    document.querySelector(`[id="${item}"]`).checked = true
  })

  const selectedStatus = urlParams.get('stage') ? urlParams.get('stage').split(",") : []
  urlParams.get('stage') && filterSelectStage.value.push(...urlParams.get('stage').split(","))
  selectedStatus.forEach(item => {
    document.querySelector(`[id="${item}"]`).checked = true
  })
}

const dealTypeChecked = (dealType, checked) => {
  if (checked) {
    filterSelectedDealType.value.push(dealType)
  } else {
    const index = filterSelectedDealType.value.indexOf(dealType);
    if (index > -1) {
      filterSelectedDealType.value.splice(index, 1);
    }
  }
}

const stageChecked = (stage, checked) => {
  if (checked) {
    filterSelectStage.value.push(stage)
  } else {
    const index = filterSelectStage.value.indexOf(stage);
    if (index > -1) {
      filterSelectStage.value.splice(index, 1);
    }
  }
}

const isLoading = ref(true);

const applyFilter = (param) => {

  let searshParam = {}
  Object.assign(searshParam, filterKeyWord.value ? { keyword: filterKeyWord.value } : null);
  Object.assign(searshParam, filterComProName.value ? { 'name-keyword': filterComProName.value } : null);
  Object.assign(searshParam, filterSelectedCommodities.value?.length ? { commodity: filterSelectedCommodities.value.join(",") } : null);
  Object.assign(searshParam, filterSelectedCountries.value?.length ? { country: filterSelectedCountries.value.join(",") } : null);
  Object.assign(searshParam, filterSelectedDealType.value?.length ? { 'deal-type': filterSelectedDealType.value.join(",") } : null);
  Object.assign(searshParam, filterSelectStage.value?.length ? { 'stage': filterSelectStage.value.join(",") } : null);

  return router.visit(currentUrlPathName.value, {
    method: 'get',
    data: { ...searshParam },
    replace: true,
    preserveScroll: true,
    onStart: () => {
      isLoading.value = true
    },
    onFinish: () => {
      isLoading.value = false

    }
  })
}

const clearFilters = () => {
  filterKeyWord.value = ''
  filterComProName.value = ''
  filterSelectedCommodities.value = null
  filterSelectedCountries.value = null
  filterSelectedDealType.value = []
  filterSelectStage.value = []

  homeFilterState.setIsOpen(false)

  applyFilter()
}

// ===========================================================================

onMounted(() => {

  window.onbeforeunload = function () {
    window.scrollTo(0, 0); 
  }

  router.on('start', () => {
    isLoading.value = true
  })

  router.on('finish', () => {
    isLoading.value = false
  })


  let queryObject = {}
  const urlString = currentUrl.value.href
  queryObject.value = [...new URLSearchParams(urlString.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {})

  selectedTopSearchCommoditiesLocal.value = queryObject.value.commodity
  selectedTopSearchKeywordLocal.value = queryObject.value.keyword
  selectedTopSearchCountryLocal.value = queryObject.value.country

  getQueryFilters()

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (props.listingsCount > currentCount.value) {
          fetchListings()
        }
      }
    });
  });
})

let queryObject = {}
const urlString = currentUrl.value.href
queryObject.value = [...new URLSearchParams(urlString.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {})

selectedTopSearchCommoditiesLocal.value = queryObject.value.commodity
selectedTopSearchKeywordLocal.value = queryObject.value.keyword
selectedTopSearchCountryLocal.value = queryObject.value.country


const listing_limit_per_load = ref(18);
let shuffledListings = [];
let listingsShuffledLocal = ref([]);

const listingsShuffle = async (data) => {

  const priorityItems = data.filter(item => item.is_verified || item.is_new_property);
  const remainingItems = data.filter(item => !priorityItems.includes(item));

  for (let i = priorityItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [priorityItems[i], priorityItems[j]] = [priorityItems[j], priorityItems[i]];
  }
  for (let i = remainingItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [remainingItems[i], remainingItems[j]] = [remainingItems[j], remainingItems[i]];
  }


  const newShuffledListings = priorityItems.concat(remainingItems);

  shuffledListings = [...shuffledListings, ...newShuffledListings.filter(item => !shuffledListings.includes(item))];

  listingsShuffledLocal.value = shuffledListings; //.slice(0, 18);
};

listingsShuffle(props.listings || [])

const initialUrl = usePage().url
const currentCount = ref(listing_limit_per_load.value)

const fetchListings = async () => {
  isLoading.value = true

  const res = await axios.get(initialUrl, {
    params: {
      skip: currentCount.value
    },
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })

  currentCount.value = currentCount.value + 18
  listingsShuffle(res.data.listings)
  isLoading.value = false
}

const goToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const vistCatergoryURL = (url, data) => {
  const queryParams = props.is_home_page_map_view ? '?map-view=true' : '';
  if (url && data) {
    const targetURL = `/${url}/${data}${queryParams}`;
    router.visit(targetURL);
  }
  else {
    if (props.is_home_page_map_view) {
      const targetURL = `/${queryParams}`;
      router.visit(targetURL);
    }
    else {
      router.visit('/');
    }

  }
}

const modules = [Navigation, Autoplay];

const activeMenu = ref('people-web');
const menuIcon = ref('');
const menuIconActive = ref('');

const loadFrame = (item) => {
  let path = '';

  switch( item ) {
    case 'people-web':
      path = './people-web-frame';
      activeMenu.value = 'people-web';
      menuIcon.value = 'Peoples-Web';
      menuIconActive.value = 'People-Web-white';
      break;
    case 'projects-map':
      path = './projects-map-frame?map-view=true';
      activeMenu.value = 'projects-map';
      menuIcon.value = 'Projects-Map';
      menuIconActive.value = 'Projects-Map-white';
      break;
    case 'news-map':
      const today = new Date();
      path = `./news-map-frame?Date=${today.toJSON().slice(0,10)}`;
      activeMenu.value = 'news-map';
      menuIcon.value = 'News-Map';
      menuIconActive.value = 'News-Map-white';
      break;
  }

  document.getElementById('ai-web-frame').src = path;
}

const urlSlugCreate = (data) => {
 // replace all special characters | symbols with a space
 let str = data.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

 // trim spaces at the start and end of the string
 str = str.replace(/^\s+|\s+$/gm, '');

 // replace space with dash/hyphen
 str = str.replace(/\s+/g, '-');

 return str;
};

const projectUrl = (item) => {
  const dealTypeSlug = item.deal_type? item.deal_type == "Unknown" ?"other":urlSlugCreate(item.deal_type):"none";
  const commoditiSlug = item.primary_commodity? urlSlugCreate(item.primary_commodity): "none"
  const countrySlug = item.country? urlSlugCreate(item.country): "none";

  const url = '/' + dealTypeSlug + '/' + commoditiSlug + '/' + countrySlug + '/' + item.company_name_and_project_name_slug;
  return url;
}

const toast = useToast();
const subscriptionEmail = ref("");
const { subscribeNewsletter } = useSubscribeNewsletter();

const isSubscribing = ref(false);

const clickSubscription = async () => {
  isSubscribing.value = true;
  subscribeNewsletter({
    email: subscriptionEmail.value,
    name: subscriptionEmail.value,
    csrf_token: props.csrf_token,
  })
    .then((res) => {
      if (res.status === 202) {
        subscriptionEmail.value = "";
        isSubscribing.value = false;
        toast.add({
          severity: "success",
          detail: "Thank you for subscribing!",
          life: 5000,
        });
      }
    })
    .catch((err) => {
      isSubscribing.value = false;
      console.log("Error while subscribing", err);
      toast.add({
        severity: "error",
        detail: "Something went wrong.",
        life: 5000,
      });
    });
};

const selectedWeeklyNewsAnalysis = ref(44);

const showWeeklyAnalysisModal = ref(false);
const showExample1Modal = ref(false);
const showExample2Modal = ref(false);
const showContactusmodal = ref(false)
const showThankyouMsg = ref(false)
const showModalboday = ref(true)

const today = computed(() => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
})

const { data: newsReleases } = useGetTodaysPressReleases(today)

const form = useForm({
  email_contact: null,
  name_contact: null,
  message_contact: null,
  authenticity_token: props.csrf_token,
  reCaptchaToken: null
})
const submit = (e) => {
  form.post('/contact/process_contact_form', {
    preserveState: true, onSuccess: () => {
      showModalboday.value = false;
      showThankyouMsg.value = true;
      form.reset();
      setTimeout(() => {
        showContactusmodal.value = false;
      }, 5000);
    }
  })
}

const verifyMethod = (response) => {

form.reCaptchaToken = response
}

const expiredMethod = () => {
form.reCaptchaToken = ""
}

const s3BaseUrl = computed(() => {
    return props.baseImageUrl;
});

const setVideoPlay = (event) => {
  console.log(event.currentTarget.getAttribute('href'));
  document.getElementById('ytSelectedVideo').src = event.currentTarget.getAttribute('href');
}

</script>

<template>
  <headComponent 
    :description="'SearchSpace AI brings you the latest mining news, best gold mines, Silver mining investments, mining news AI and a comprehensive mining network.'"
    :title="'Find Your Next Mining & Mining Investment'">
  </headComponent>

  <section>
    <!-- Home banner starts -->
    <HomeBanner 
      :csrf_token="csrf_token" 
      :captcha_sitekey="captchaSiteKey"
      :is_commodity_slug="is_commodity_slug" 
      :is_country_slug="is_country_slug" 
      :is_deal_type_slug="is_deal_type_slug" 
      :is_stage_slug="is_stage_slug" 
      :deal_type_name_in_slug="deal_type_name_in_slug" 
      :commodity_name_in_slug="commodity_name_in_slug" 
      :stage_name_in_slug="stage_name_in_slug" 
      :country_name_in_slug="country_name_in_slug"  />
    <!-- Home banner ends -->
  </section>

  <section>
    <a href="#" class="top-to-scroll scroll-on-top" @click="goToTop()">
      <i class="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
  </section>

  <div class="banner-filter-for-home">
    <section style="position: absolute; width: 100%;">
      <!-- Tab panes -->
      <div class="tab-content mt-3 pt-0 home-page-map-content">

        <!-- Latest News Panel starts -->
        <div class="container latest-news-panel container-fluid flex-d mt-3 pt-4 pb-4">
          <div class="row">
            <div class="mx-auto mb-3">
              <h3 class="mb-2 text-center">Stay Updated with the Latest Junior Mining News!</h3>
              <p class="mb-4 text-center">Stay informed and be at the forefront of mining developments with our Latest News about Mining Projects. Our platform brings you real-time updates on exploration breakthroughs, cutting-edge technologies, sustainable practices, and industry trends.</p>
            </div>
            <div class="col-lg-7 news-items-container">
              <div class="news-item-wrapper flex-d flex-column justify-content-around me-lg-4">
                <swiper class="latest-news-slider h-100" 
                  slides-per-view="3" 
                  direction="vertical"
                  :autoplay="{delay: 3000, disableOnInteraction: false, reverseDirection: true, pauseOnMouseEnter: true}"
                  :loop="true"
                  :speed="1500">
                  <swiper-slide v-for="item in newsReleases">

                    <!-- Backward compatibility to view PDF Popup, if HTML is not available -->
                    <PdfModal v-if="item.body == undefined || item.body==''" :link="`${s3BaseUrl}/${item.link_s3}`">
                        <div class="news-item mb-4">
                          <div v-if="false" class="news-img-container">
                            <img class="img-fluid" src="../../assets/img/vizsla-silver-corp.png" alt="">
                          </div>
                          <div class="news-item-content px-4 py-3">
                            <h6 class="title ">{{ item.title }}</h6>
                            <div class=" mb-3"  v-if="item.summary_points.length > 0">
                              <div v-for="(data, dataIndex) in item.summary_points" :key="dataIndex" style="margin-bottom: -15px;">
                                <div v-if="data">
                                  <ul class="p-0 my-2">
                                    <li class="pb-1 summary-point" v-for="(point, pointIndex) in data.split('\n-').filter(p => p.trim() !== '')" :key="pointIndex">
                                      {{ point.replace(/^-\s*/, '') }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="container px-0 attributes d-inline-flex">
                              <div class="pr-date pe-3">Date: <strong>{{ item.publish_date }}</strong></div>
                              <div class="pr-company">Company: <strong>{{ item.company_name }}</strong></div>
                            </div>
                          </div>
                        </div>
                    </PdfModal>

                    <a v-if="item.body" :href="`/press-releases/${item.provider}/${item.slug || item._id.$oid}?ref=homepage`">
                      <div class="news-item mb-4">
                        <div v-if="false" class="news-img-container">
                          <img class="img-fluid" src="../../assets/img/vizsla-silver-corp.png" alt="">
                        </div>
                        <div class="news-item-content px-4 py-3">
                          <h6 class="title ">{{ item.title }}</h6>
                          <div class=" mb-3" v-if="item.summary_points.length > 0">
                            <div v-for="(data, dataIndex) in item.summary_points" :key="dataIndex" style="margin-bottom: -15px;">
                              <div v-if="data">
                                <ul class="p-0 my-2">
                                  <li class="pb-1 summary-point" v-for="(point, pointIndex) in data.split('\n-').filter(p => p.trim() !== '')" :key="pointIndex">
                                    {{ point.replace(/^-\s*/, '') }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="container px-0 attributes d-inline-flex">
                            <div class="pr-date pe-3">Date: <strong>{{ item.publish_date }}</strong></div>
                            <div class="pr-company">Company: <strong>{{ item.company_name }}</strong></div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
            <div class="col-lg-5 news-video-container">
              <a href="https://www.heliostarmetals.com/" target="_blank">
                <img class="news-video-img mx-0 my-2 img-fluid" src="../../assets/img/heliostar.png" border=0 alt="">
              </a>
              <iframe class="mx-0 mb-2" width="100%" height="75%" src="https://www.youtube.com/embed/nxOjk976lTQ?si=XkbUGZ7aSGozcNXX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <!-- Latest News panel ends -->
         
        <!-- People Web Panel starts -->
        <div class="ai-web-pannel container embed-responsive embed-responsive-4by3 p-0 mb-5">
         <div class="web-menu nav d-flex nav-pills m-2">
            <a id="menu-people-web" :class="`nav-link ${activeMenu=='people-web'?'people-web active':''}`" @click="loadFrame('people-web')" href="#">
              <img src="../../assets/icons/web-menu/People-Web.png">PeopleWeb</a>
            <a id="menu-project-map" :class="`nav-link ${activeMenu=='projects-map'?'projects-map active':''}`" @click="loadFrame('projects-map')" href="#">
              <img src="../../assets/icons/web-menu/Projects-Map.png">Projects&nbsp;Map</a>
            <a id="menu-news-map" :class="`nav-link ${activeMenu=='news-map'?'news-map active':''}`" @click="loadFrame('news-map')" href="#">
              <img src="../../assets/icons/web-menu/News-Map.png">News Map</a>
          </div>
          <iframe id="ai-web-frame" src="/people-web-frame" height="614" width="100%" frameborder="0" scrolling="no"></iframe>
        </div>
        <!-- People Web Panel ends -->

        <!-- Group Panel starts -->
        <div class="container group-panel">
          <div class="row">
            <div class="col-lg-4 p-xs-2 p-sm-0 pe-lg-2">
              <div class="group-box text-center mb-3 d-flex flex-column">
                <h5>FOR PROSPECTORS AND GEOLOGISTS</h5>
                <div class="bg-img img-1"></div>
                <h4 class="mb-2">Junior Mining Project Marketplace</h4>
                <h6 class="mb-4">Meet your next potential investor</h6>
                <p class="mb-4">Investors are actively scouting for the next investment opportunities. Listing your projects enhances visibility, facilitates access to capital, and, ultimately, accelerates your growth and success.</p>
                <div class="text-center mt-auto">
                  <a href="/my-listings/new" class="btn custom-primary-btn">List your Project for Free</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-xs-2 p-sm-0 px-lg-2">
              <div class="group-box text-center mb-3 d-flex flex-column">
                <h5>FOR INVESTORS, ANALYSTS, MARKET FOLLOWERS</h5>
                <div class="bg-img img-2"></div>
                <h4 class="mb-2">Al Powered Dashboards</h4>
                <h6 class="mb-4">Analyse your next potential investment</h6>
                <p class="mb-4">Explore the capabilities of our Al-powered business intelligence (BI) tool for analysing companies, projects and people in the junior mining sector. Play with our dashboards to gain valuable and actionable insights for informed decision-making.</p>
                <div class="text-center mt-auto">
                  <a href="/searchspace-ai" class="btn custom-primary-btn">View Al Insights</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-xs-2 p-sm-0 ps-lg-2">
              <div class="group-box text-center mb-3 d-flex flex-column">
                <h5>FOR JUNIOR MINING FOLLOWERS</h5>
                <div class="bg-img img-3"></div>
                <h4 class="mb-2">Summarised Daily News</h4>
                <h6 class="mb-4">Stay ahead in the hunt</h6>
                <p class="mb-4">Stay tuned for daily news summaries that offer time-efficient, digestible information, providing broad coverage for informed decision-making.</p>
                <div class="text-center mt-auto">
                  <a href="/press-releases" class="btn custom-primary-btn">Read News Updates</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Group Panel ends -->

        <!-- Ad banner starts -->
        <div class="container text-center my-5 py-4">
          <a href="https://xploraweb.com/" target="_blank"><img class="img-fluid" src="../../assets/img/ads/xplorar-banner.jpg" width="976" alt="Explorar"></a> 
        </div>
        <!-- Ad banner ends -->

        <!-- Featured Projects Panel starts -->
        <div class="container featured-projects-panel py-4">
          <h3 class="text-center">Featured Junior Mining Projects</h3>

          <div class="featured-projects clearfix">
            <swiper class="home-slider my-4" 
              :slides-per-view="'auto'"
              :centered-slides="true"
              :modules="modules" 
              :navigation="true"
              direction="horizontal"
              :autoplay="{delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true}"
              :loop="true"
              :speed="1500">
              <swiper-slide class="listing-card mx-2 p-0 border" v-for="(item, index) in listingsShuffledLocal" :key="index">
                <a :href="projectUrl(item)">
                  <div class="card-img tw-overflow-hidden relative border-bottom">
                    <div class="d-flex justify-content-end verified-new-icons absolute top-0 end-0 p-2 icons-verified-new">
                        <div class="click-to-top-new">
                            <img loading="lazy" v-if="item.is_new_property" src="../../assets/img/new.png"
                                alt="New Property">
                            <span class="tooltip-text" v-if="item.is_new_property">New</span>
                        </div>
                        <div loading="lazy" class="click-to-top-verified">
                            <img v-if="item.is_verified" class="ms-2" src="../../assets/img/verified.png"
                                alt="Verified Property">
                            <span class="tooltip-text" v-if="item.is_verified">Verified</span>
                        </div>
                    </div>
                    <template v-if="item.cover_image===''">
                      <img src="../../assets/img/project-placeholder.jpg" alt="Property image" class="tw-w-full tw-h-full tw-object-cover">
                    </template>
                    <template v-else>
                      <img loading="lazy" :src="item.cover_image" alt="Property image" class="tw-w-full tw-h-full tw-object-cover">
                    </template>
                  </div>
                  <div class="card-stats d-flex justify-content-around">
                    <div class="card-stat views"><i class="fa fa-eye" style="color:#1D49A7"></i>&nbsp;{{ item.total_views }} View{{ item.total_views==1?"":"s" }}</div>
                    <div v-if="false" class="card-stat rating"><i class="fa fa-star" style="color:#FCEB4F;"></i>&nbsp;4.5</div>
                    <button v-if="false" class="card-stat save"><i class="fa fa-bookmark-o"></i>&nbsp;Save</button>
                  </div>
                  <div class="p-3">
                    <h6 class="mb-1">{{ item.name }}</h6>
                    <div class="d-flex">
                      <h5>{{ item.company_name }}</h5>
                      <div loading="lazy" class="click-to-top-verified company">
                        <i class="verified-icon ms-2 fa fa-check-circle" alt="Verified Property"></i>
                        <span class="tooltip-text" v-if="item.is_verified">Verified</span>
                      </div>
                    </div>

                    <h5 class="mb-2">{{ item.country }}</h5>
                    <div class="d-flex justify-content-between">
                      <p class="">{{ item.deal_type }}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="">{{ item.stage }}</p>
                      <p class="">{{ item.claim_size }} &nbsp;{{ item.claim_unit_size }}</p>
                    </div>
                    <p class="commodities">{{ item.primary_commodity }} 
                      <span v-if="item.secondary_commodity">|</span>
                      {{ item.secondary_commodity }}
                    </p>
                    <button v-if="false" class="mx-2 mb-3 share-btn"><i class="fa fa-share-square-o"></i>&nbsp;Share</button>
                  </div>
                </a>
              </swiper-slide>
            </swiper>
          </div>
          <h6 class="mt-5 text-center">I like my project featured&nbsp;&nbsp;<a type="button" data-bs-modal="modal" @click="showContactusmodal = !showContactusmodal" class="btn custom-primary-btn no-background">Learn More</a></h6>
        </div>
        <!-- Featured Projects Panel ends -->

        <!-- Ad banner starts -->
        <div class="container text-center my-5 py-4">
          <a href="https://www.heliostarmetals.com/" target="_blank"><img class="img-fluid" src="../../assets/img/ads/heliostar-banner.png" width="976" alt="Heliostar"></a> 
        </div>
        <!-- Ad banner ends -->

        <!-- Investment News Panel starts -->
        <div class="container investment-news-panel mt-5">
          <div class="row">
            <!-- Weekly News Analysis -->
            <div class="col-lg-6 px-xs-2 px-sm-0 mb-3 pe-lg-2">
              <div class="investment-news text-center">
                <div class="oval text-center">
                  <p>Exclusively on Email, LinkedIn, Twitter, Facebook</p>
                </div> 
                <h3 class="title pt-3">FREE FOR OUR PATRONS</h3>
                <div class="analysis py-3 px-sm-3">
                  <swiper class="selected-news-articles home-slider mb-4" 
                    :effect="'coverflow'"
                    :grabCursor="true"
                    :centeredSlides="true"
                    :slidesPerView="'auto'"
                    :initialSlide="1"
                    :coverflowEffect="{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }"
                    :navigation="true"
                    :modules="modules">
                    <swiper-slide @click="showWeeklyAnalysisModal=true"><img loading="lazy" :src="`./img/weekly-news-analysis/${selectedWeeklyNewsAnalysis}/1.jpg`"></swiper-slide>
                    <swiper-slide @click="showWeeklyAnalysisModal=true"><img loading="lazy" :src="`./img/weekly-news-analysis/${selectedWeeklyNewsAnalysis}/2.jpg`"></swiper-slide>
                    <swiper-slide @click="showWeeklyAnalysisModal=true"><img loading="lazy" :src="`./img/weekly-news-analysis/${selectedWeeklyNewsAnalysis}/3.jpg`"></swiper-slide>
                    <swiper-slide @click="showWeeklyAnalysisModal=true"><img loading="lazy" :src="`./img/weekly-news-analysis/${selectedWeeklyNewsAnalysis}/4.jpg`"></swiper-slide>
                  </swiper>

                  <swiper ref="weeklyAnalysisSlider" class="main-weekly-analysis home-slider mx-auto mb-4" 
                    :slidesPerView="3"
                    :initialSlide="0"
                    :breakpoints="{
                      378: { slidesPerView: 4 },
                      576: { slidesPerView: 4 },
                      768: { slidesPerView: 6 },
                      992: { slidesPerView: 4 },
                      1200: { slidesPerView: 5 },
                    }"
                    :modules="modules" 
                    :navigation="true">
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=44"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==44)}" :src="`./img/weekly-news-analysis/44/1.jpg`"><p>06th Jan-12th Jan<br>2025</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=43"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==43)}" :src="`./img/weekly-news-analysis/43/1.jpg`"><p>30th Dec 2024-05th Jan 2025</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=42"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==42)}" :src="`./img/weekly-news-analysis/42/1.jpg`"><p>23rd Dec-29th Dec<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=41"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==41)}" :src="`./img/weekly-news-analysis/41/1.jpg`"><p>16th Dec-22nd Dec<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=40"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==40)}" :src="`./img/weekly-news-analysis/40/1.jpg`"><p>09th Dec-15th Dec<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=39"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==39)}" :src="`./img/weekly-news-analysis/39/1.jpg`"><p>02nd Dec-08th Dec<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=38"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==38)}" :src="`./img/weekly-news-analysis/38/1.jpg`"><p>25th Nov-02nd Dec<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=37"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==37)}" :src="`./img/weekly-news-analysis/37/1.jpg`"><p>18th Nov-24th Nov<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=36"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==36)}" :src="`./img/weekly-news-analysis/36/1.jpg`"><p>11th Nov-17th Nov<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=35"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==35)}" :src="`./img/weekly-news-analysis/35/1.jpg`"><p>04th Nov-10th Nov<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=34"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==34)}" :src="`./img/weekly-news-analysis/34/1.jpg`"><p>28th Oct-03rd Nov<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=33"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==33)}" :src="`./img/weekly-news-analysis/33/1.jpg`"><p>21st Oct-27th Oct<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=32"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==32)}" :src="`./img/weekly-news-analysis/32/1.jpg`"><p>14th Oct-20th Oct<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=31"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==31)}" :src="`./img/weekly-news-analysis/31/1.jpg`"><p>07th Oct-13th Oct<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=30"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==30)}" :src="`./img/weekly-news-analysis/30/1.jpg`"><p>30th Sep-06th Oct<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=29"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==29)}" :src="`./img/weekly-news-analysis/29/1.jpg`"><p>23rd Sep-29th Sep<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=28"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==28)}" :src="`./img/weekly-news-analysis/28/1.jpg`"><p>16th Sep-22nd Sep<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=27"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==27)}" :src="`./img/weekly-news-analysis/27/1.jpg`"><p>09th Sep-15th Sep<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=26"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==26)}" :src="`./img/weekly-news-analysis/26/1.jpg`"><p>02nd Sep-08th Sep<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=25"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==25)}" :src="`./img/weekly-news-analysis/25/1.jpg`"><p>26th Aug-01st Sep<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=24"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==24)}" :src="`./img/weekly-news-analysis/24/1.jpg`"><p>19th Aug-25th Aug<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=23"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==23)}" :src="`./img/weekly-news-analysis/23/1.jpg`"><p>12th Aug-18th Aug<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=22"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==22)}" :src="`./img/weekly-news-analysis/22/1.jpg`"><p>05th Aug-11th Aug<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=21"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==21)}" :src="`./img/weekly-news-analysis/21/1.jpg`"><p>29th Jul-04th Aug<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=20"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==20)}" :src="`./img/weekly-news-analysis/20/1.jpg`"><p>22nd Jul-28th Jul<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=19"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==19)}" :src="`./img/weekly-news-analysis/19/1.jpg`"><p>15th Jul-21st Jul<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=18"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==18)}" :src="`./img/weekly-news-analysis/18/1.jpg`"><p>08th Jul-14th Jul<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=17"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==17)}" :src="`./img/weekly-news-analysis/17/1.jpg`"><p>01st Jul-07th Jul<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=16"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==16)}" :src="`./img/weekly-news-analysis/16/1.jpg`"><p>24th Jun-30th Jun<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=15"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==15)}" :src="`./img/weekly-news-analysis/15/1.jpg`"><p>17th Jun-22th Jun<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=14"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==14)}" :src="`./img/weekly-news-analysis/14/1.jpg`"><p>10th Jun-16th Jun<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=13"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==13)}" :src="`./img/weekly-news-analysis/13/1.jpg`"><p>03rd Jun-08th Jun<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=12"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==12)}" :src="`./img/weekly-news-analysis/12/1.jpg`"><p>27th May-02nd Jun<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=11"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==11)}" :src="`./img/weekly-news-analysis/11/1.jpg`"><p>20th May-26th May<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=10"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==10)}" :src="`./img/weekly-news-analysis/10/1.jpg`"><p>13th May-18th May<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=9"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==9)}" :src="`./img/weekly-news-analysis/9/1.jpg`"><p>06th May-11th May<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=8"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==8)}" :src="`./img/weekly-news-analysis/8/1.jpg`"><p>29th Apr-05th May<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=7"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==7)}" :src="`./img/weekly-news-analysis/7/1.jpg`"><p>22nd Apr-28th Apr<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=6"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==6)}" :src="`./img/weekly-news-analysis/6/1.jpg`"><p>15th Apr-21st Apr<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=5"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==5)}" :src="`./img/weekly-news-analysis/5/1.jpg`"><p>08th Apr-14th Apr<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=4"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==4)}" :src="`./img/weekly-news-analysis/4/1.jpg`"><p>01st Apr-07th Apr<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=3"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==3)}" :src="`./img/weekly-news-analysis/3/1.jpg`"><p>25th Mar-31st Mar<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=2"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==2)}" :src="`./img/weekly-news-analysis/2/1.jpg`"><p>18th Mar-24th Mar<br>2024</p></swiper-slide>
                    <swiper-slide @click="selectedWeeklyNewsAnalysis=1"><img loading="lazy" :class="{active: (selectedWeeklyNewsAnalysis==1)}" :src="`./img/weekly-news-analysis/1/1.jpg`"><p>11th Mar-17th Mar<br>2024</p></swiper-slide>
                  </swiper>

                  <div class="px-2">
                    <h3 class="mb-2" style="font-weight:400">Investment News Weekly Analysis</h3>
                    <h4 class="mb-4">Specially curated investor insights for you</h4>
                    <p>We provide a concise weekly overview of mining news, highlighting issued and closed finances to reveal market investment gaps. Our analysis includes tracking weekly investment trends in commodities and companies, aiding informed investment decisions. Receive this valuable data directly in your inbox each week, or stay connected with us on Linkedin, Twitter, and Facebook for regular updates.</p>
                    <hr class="my-4">
                    <div class="row text-center">
                      <form @submit.prevent="clickSubscription">
                        <div class="my-2 d-flex email-subscribe">
                          <input type="email" class="form-control" id="floatingInput" placeholder="Enter your email address to receive our weekly analysis"
                            v-model="subscriptionEmail" required />
                          <button class="btn btn-outline-secondary" type="submit" id="button-addon1">
                            {{ isSubscribing ? 'Please wait...' : 'Subscribe' }}
                          </button>
                        </div>
                      </form>
                    </div>
                    <h6 class="my-2">Follow us on our Social Media Channels to View latest News and Analysis</h6>
                    <div class="social-media-links mb-2">
                      <a class="me-2" href="https://twitter.com/SearchSpaceAI" alt="X" target="_blank"><img src="../../assets/icons/x-twitter.svg" ></a>
                      <a class="me-2" href="https://www.linkedin.com/company/search-space-ai" alt="LinkedIn" target="_blank"><i class="fa fa-linkedin-square"></i></a>
                      <a class="me-2" href="https://www.facebook.com/SearchSpaceGEO" alt="Facebook" target="_blank"><i class="fa fa-facebook-square"></i></a>
                      <a class="me-2" href="https://www.youtube.com/@searchspaceAI/videos" alt="Youtube" target="_blank"><i class="fa fa-youtube-square"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 px-xs-2 px-sm-0 mb-3 ps-lg-2">
              <div class="whats-new text-center">
                <h3 class="title pt-5">CHECK WHAT'S NEW</h3>
                <div class="yt-videos-container">
                  <div class="yt-video-player pb-2 px-3">
                    <iframe id="ytSelectedVideo" class="mb-2" width="100%" height="320" src="https://www.youtube.com/embed/moITKUPJiLk" title="How to create an account on SearchSpace AI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                  <div class="yt-video-thumbs px-3 mt-0 clearfix align-items-center">
                    <div class="yt-thumb"><a href="https://www.youtube.com/embed/8pERpoYrKUE?autoplay=1" @click.prevent="setVideoPlay($event)" target="_blank"><img class="img-fluid" src="https://img.youtube.com/vi/8pERpoYrKUE/sddefault.jpg" alt=""></a></div>
                    <div class="yt-thumb"><a href="https://www.youtube.com/embed/M77j1pp4H6s?autoplay=1" @click.prevent="setVideoPlay($event)" target="_blank"><img class="img-fluid" src="https://img.youtube.com/vi/M77j1pp4H6s/sddefault.jpg" alt=""></a></div>
                    <div class="yt-thumb"><a href="https://www.youtube.com/embed/7oqEFjAtlAQ?autoplay=1" @click.prevent="setVideoPlay($event)" target="_blank"><img class="img-fluid" src="https://img.youtube.com/vi/7oqEFjAtlAQ/sddefault.jpg" alt=""></a></div>
                    <div class="yt-thumb"><a href="https://www.youtube.com/embed/V7RhCsAKYgo?autoplay=1" @click.prevent="setVideoPlay($event)" target="_blank"><img class="img-fluid" src="https://img.youtube.com/vi/V7RhCsAKYgo/sddefault.jpg" alt=""></a></div>
                    <div class="yt-thumb"><a href="https://www.youtube.com/embed/bmtocu4VqPY?autoplay=1" @click.prevent="setVideoPlay($event)" target="_blank"><img class="img-fluid" src="https://img.youtube.com/vi/bmtocu4VqPY/sddefault.jpg" alt=""></a></div>
                    <div class="yt-thumb"><a href="https://www.youtube.com/embed/7oqEFjAtlAQ?autoplay=1" @click.prevent="setVideoPlay($event)" target="_blank"><img class="img-fluid" src="https://img.youtube.com/vi/D7tmO_eSrQo/sddefault.jpg" alt=""></a></div>
                  </div>
                  <div class="text-center my-4">
                    <a href="https://www.youtube.com/@searchspaceAI/videos" class="btn custom-primary-btn" target="_blank">Visit our Youtube channel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Investment News Panel ends -->

        <!-- Ad banner starts -->
        <div class="container text-center my-4 py-4">
          <a href="https://www.heliostarmetals.com/" target="_blank"><img class="img-fluid" src="../../assets/img/ads/heliostar-banner.png" width="976" alt="Heliostar"></a> 
        </div>
        <!-- Ad banner ends -->

        <!-- Companies Panel starts -->
        <div id="companies-panel" class="container companies-panel px-xs-2 px-sm-0 pb-2 mt-5 pt-3">
          <div class="row">
            <div class="mx-auto col-lg-10 pt-2">
              <h3 class="my-2 text-center">Junior Mining Companies in our Database</h3>
            </div>
            <div class="company-logos slider">
              <swiper class="home-slider"
                slides-per-view="2" 
                :breakpoints="{
                  480: { slidesPerView: 3 },
                  480: { slidesPerView: 3 },
                  768: { slidesPerView: 4 },
                  1024: { slidesPerView: 5 },
                  1200: { slidesPerView: 6 },
                }"
                :modules="modules" 
                :navigation="true"
                direction="horizontal"
                :autoplay="{delay: 1000, disableOnInteraction: false, pauseOnMouseEnter: true}"
                :loop="true"
                :speed="1500">
                <template v-for="(index) in 98">
                  <swiper-slide v-if="(index+1)%3==0">
                    <div class="d-flex flex-wrap justify-content-center">
                      <div class="company-logo"><img class="lazy" :src="`${s3BaseUrl}/public/images/company-logos-people-web/CMP_${index-2}.png`" :alt="`CMP_${index-2}`"></div>
                      <div class="company-logo"><img class="lazy" :src="`${s3BaseUrl}/public/images/company-logos-people-web/CMP_${index-1}.png`" :alt="`CMP_${index-1}`"></div>
                      <div class="company-logo"><img class="lazy" :src="`${s3BaseUrl}/public/images/company-logos-people-web/CMP_${index}.png`" :alt="`CMP_${index}`"></div>
                    </div>
                  </swiper-slide>
                </template>
              </swiper>
            </div>

            <div class="mx-auto mt-4 col-lg-10">
              <h3 class="add-company-title mb-2 text-center">Did We Miss You?</h3>
              <p class="mb-4 text-center">SearchSpace Al is a hub of diverse range of junior mining projects and investment opportunities, from precious metals and minerals. By partnering with SearchSpace Al, you gain access to a wide audience of qualified investors who are actively seeking opportunities in the mining space.</p>
              <div class="text-center mb-4">
                <a href="/my-listings/new" type="button" class="btn custom-primary-btn">Add Your Company</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Companies panel ends -->
        
        <!-- Subscribe Panel starts -->
        <div class="newswire-subscribe-panel container-fluid flex-d mt-3 mb-5 pb-5">
          <div class="container">
            <div class="row">
              <div class="subscribe-newswire-video col-md-6 mt-4 mb-2 px-0 pe-sm-2 pe-md-4 pe-lg-5">
                <iframe width="100%" height="360" src="https://www.youtube.com/embed/apX7X8_lgPM" title="Subscribe to SSAI Newsletter" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
              <div class="subscribe-newswire-content col-md-6 p-0 mt-4 mb-4 ps-lg-5">
                <h3 class="mb-4">Subscribe to Our Free SearchSpace Al&nbsp;News&nbsp;Wire</h3>
                <h5 class="mb-2">Get daily mining news to your email inbox. <a type="button" data-bs-modal="modal" @click="showExample1Modal=!showExample1Modal" class="help-link">See&nbsp;example</a></h5>
                <ul class="subscription-benifits">
                  <li>Daily breaking news in mining industry</li>
                  <li>Customised preferences</li>
                  <li>Read news on the map</li>
                  <li>Access to thousands of past news releases</li>
                  <li>Summary of news releases</li>
                </ul>
                <h5 class="mb-4">Get investment news weekly analysis to your email inbox. <a type="button" data-bs-modal="modal" @click="showExample2Modal=!showExample2Modal" class="help-link">See&nbsp;example</a></h5>
                <div class="email-subscribe">
                  <form @submit.prevent="clickSubscription" class="mb-3">
                    <div class="mt-2 d-flex email-subscribe">
                      <input type="email" class="form-control" id="floatingInput" placeholder="Enter your email address"
                        v-model="subscriptionEmail" required />
                      <button class="btn btn-outline-secondary" type="submit" id="button-addon2">
                        {{ isSubscribing ? 'Please wait...' : 'Subscribe' }}
                      </button>
                    </div>
                  </form>
                  <p class="add-project text-end">By clicking on "Subscribe" button, you accept our <a href="/terms-and-conditions" class="help-link">terms&nbsp;and&nbsp;conditions</a>,&nbsp;<a href="/privacy" class="help-link">privacy&nbsp;policy</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Subscribe panel ends -->
      </div>
    </section>
  </div>

  <!-- Weekly Analysis Modal -->
  <BsModal :show="showWeeklyAnalysisModal" id="weekly-analysis-modal" class="modal-lg">
    <template #modalHeader>
      <h5 class="modal-title">Weekly Analysis</h5>
      <button type="button" class="btn-close" aria-label="Close" @click="showWeeklyAnalysisModal=false"></button>
    </template>
    <template #modalBody>
      <div class="row">
        <div class="px-5 text-center">
          <img class="img-fluid" :src="`./img/weekly-news-analysis/${selectedWeeklyNewsAnalysis}/full-analysis.jpg`" />
        </div>
      </div>
    </template>
  </BsModal>
  <!-- Example 2 Modal ends -->

  <!-- Example 1 Modal -->
  <BsModal :show="showExample1Modal" id="daily-mining-news-example">
    <template #modalHeader>
      <h5 class="modal-title">Daily Mining News</h5>
      <button type="button" class="btn-close" aria-label="Close" @click="showExample1Modal=false"></button>
    </template>
    <template #modalBody>
      <div class="row">
        <div class="px-5 text-center">
          <img class="img-fluid" src="../../assets/img/daily-mining-news.jpg" />
        </div>
      </div>
    </template>
  </BsModal>
  <!-- Example 1 Modal ends -->

  <!-- Example 2 Modal -->
  <BsModal :show="showExample2Modal" id="weekly-analysis-example">
    <template #modalHeader>
      <h5 class="modal-title">Weekly Analysis Example</h5>
      <button type="button" class="btn-close" aria-label="Close" @click="showExample2Modal=false"></button>
    </template>
    <template #modalBody>
      <div class="row">
        <div class="px-5 text-center">
          <img class="img-fluid" src="../../assets/img/weekly-analysis.jpg" />
        </div>
      </div>
    </template>
  </BsModal>
  <!-- Example 2 Modal ends -->

  <!-- Contact Modal -->
  <form :action="`/contact/process_contact_form`" method="POST" @submit.prevent="submit" class="mb-0">
    <BsContactModal :show="showContactusmodal" :modal_name="'ContactUS'">
      <template #modalHeader>
        <h5 class="modal-title" id="exampleModalLabel">Contact Us</h5>
        <button type="button" class="btn-close" aria-label="Close" @click="showContactusmodal = false"></button>
      </template>
      <template #modalBody>
        <div v-show="showModalboday">
          <h6 class="mt-1 mb-3">Send us a message</h6>
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" v-model="form.name_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="form.email_contact" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Message</label>
            <textarea class="form-control" v-model="form.message_contact" required></textarea>
          </div>

          <div class="mb-3">
            <vue-recaptcha ref="recaptcha" :sitekey="props.captchaSiteKey" @verify="verifyMethod"
              @expired="expiredMethod"></vue-recaptcha>

            <input type="hidden" class="form-control" v-model="form.reCaptchaToken" required>

            <div class="validation-msg">
              {{ errors?.message }}
            </div>
          </div>

          <button :disabled="form.reCaptchaToken === null" type="submit" class="btn apply-btn custom-primary-btn w-100">{{
            form.processing ? 'Please wait...' :
            'Submit' }} </button>
        </div>
        <div class="Thank-u-msg text-center mt-4" v-show="showThankyouMsg">
          <div class="success-icon"><i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <h6 class="mb-2">Thank you!</h6>
          <p class="">Your message was delivered successfully. <br /> We will get back to you as quick as possible.</p>
        </div>
      </template>
      <template #modalFooter>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/email.png" alt="Support Email" class="me-2">
          <p>support@searchspaceai.com</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/phone.png" alt="Support Phone Number" class="me-2">
          <p>+1 604 283 7679</p>
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="../../assets/img/address.png" alt="Support Address" class="me-2">
          <p>595 Burrard Street, Suit 1723, Vancouver, BC V7X 1J1.</p>
        </div>
      </template>
    </BsContactModal>
  </form>
  <!-- Contact Modal End-->

</template>

<style scoped lang="scss">
.apply-btn {
  --bs-btn-disabled-bg: #3882ff;
  padding: 5px;
}
</style>